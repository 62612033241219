import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import type { PlaceholderProps } from '@aurora/shared-client/helpers/react-beautiful-dnd/PlaceholderHelper';
import {
  calculatePlaceholderProps,
  placeholderInitialState,
  placeholderStyle
} from '@aurora/shared-client/helpers/react-beautiful-dnd/PlaceholderHelper';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useCallback, useState } from 'react';
import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DragUpdate,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult
} from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type {
  GuideViewFragment,
  MessageViewFragment,
  NodeViewFragment
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './EditFeaturedList.module.pcss';
import type { IdeaStatusDetails } from '@aurora/shared-generated/types/graphql-schema-types';

interface Props<
  ItemType extends MessageViewFragment | NodeViewFragment | IdeaStatusDetails | GuideViewFragment
> {
  /**
   * The item list
   */
  items: Array<ItemType>;
  /**
   * Callback function used to update the item list
   */
  updateItems: (items: Array<ItemType>) => void;
  /**
   * Whether the mutation to update the item list is submitting
   */
  isSubmitting: boolean;
  /**
   * Callback function used to render the item in the list
   */
  renderItemComponent: (item: ItemType) => React.ReactElement;
  /**
   * Class name(s) to apply to the container div
   */
  className?: string;
}
/**
 * Renders the list of featured items in a drag and drop list
 *
 * @author Jonathan Bridges, Luisina Santos
 */
const EditFeaturedList = <
  ItemType extends MessageViewFragment | NodeViewFragment | IdeaStatusDetails | GuideViewFragment
>({
  items,
  updateItems,
  isSubmitting,
  renderItemComponent,
  className
}: Props<ItemType>): React.ReactElement => {
  const uidSeed = useUIDSeed();
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.EDIT_FEATURED_LIST);
  const { formatMessage } = i18n;
  const [placeholderProps, setPlaceholderProps] =
    useState<PlaceholderProps>(placeholderInitialState);

  /**
   * Reorders the item list
   *
   * @param list the current item list
   * @param startIdx the index of the item being removed
   * @param endIdx the index where the item will be inserted
   */
  const reorder = useCallback(
    (list: Array<ItemType>, startIdx: number, endIdx: number): Array<ItemType> => {
      const result = [...list];
      const [removed] = result.splice(startIdx, 1);
      result.splice(endIdx, 0, removed);

      return result;
    },
    []
  );

  /**
   * Reorders the item list and updates it in component state
   *
   * @param result the DropResult
   */
  const onDragEnd = useCallback(
    (result: DropResult): void => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      setPlaceholderProps(placeholderInitialState);
      const reorderedItems: Array<ItemType> = reorder(
        [...items],
        result.source.index,
        result.destination.index
      );

      updateItems(reorderedItems);
    },
    [items, reorder, updateItems]
  );

  /**
   * Sets styling for the react-beautiful-dnd library "placeholder" element which indicates where the dragged item
   * may be placed
   *
   * @param dragUpdate the drag update props from the react-beautiful-dnd library
   */
  const onDragUpdate = useCallback((dragUpdate: DragUpdate): void => {
    const { destination, source, draggableId } = dragUpdate;

    const newPlaceholderProps = calculatePlaceholderProps({
      destination,
      source,
      draggableId,
      queryAttribute: 'data-rbd-drag-handle-draggable-id',
      getRootElement: element => element?.parentElement
    });

    setPlaceholderProps(newPlaceholderProps);
  }, []);

  /**
   * Check if the entity type is IdeaStatusDetails and typecast it if it is
   * @param item Entity to check and typecast
   * @returns Whether or not the entity is of type IdeaStatusDetails
   */
  function isIdeaStatus(
    item: MessageViewFragment | NodeViewFragment | IdeaStatusDetails | GuideViewFragment
  ): item is IdeaStatusDetails {
    return (item as IdeaStatusDetails).statusKey !== undefined;
  }

  /**
   * Renders the item contents inside the draggable div
   *
   * @param item the item
   * @param dataRbdDragHandleContextId the drag handle context id
   * @param dataRbdDragHandleDraggableId the drag handle draggable id
   * @param role the role
   * @param draggable whether the item is draggable
   * @param onDragStart the drag event handler
   * @param tabIndex the tab index
   */
  const renderItem = useCallback(
    (
      item: ItemType,
      dataRbdDragHandleContextId: string,
      dataRbdDragHandleDraggableId: string,
      role: string,
      draggable: boolean,
      onDragStart: React.DragEventHandler,
      tabIndex: number
    ) => {
      return (
        <>
          <Button
            as="div"
            variant={ButtonVariant.UNSTYLED}
            className={cx('lia-drag-handle')}
            data-rbd-drag-handle-context-id={dataRbdDragHandleContextId}
            data-rbd-drag-handle-draggable-id={dataRbdDragHandleDraggableId}
            data-testid="EditFeaturedList.Item"
            role={role}
            draggable={draggable}
            onDragStart={onDragStart}
            tabIndex={!isSubmitting ? tabIndex : -1}
            disabled={isSubmitting}
          >
            <Icon
              icon={Icons.DragIcon}
              size={IconSize.PX_16}
              color={IconColor.GRAY_900}
              className={cx('lia-drag-handle-icon')}
            />
            {renderItemComponent(item)}
          </Button>
          <Button
            className={cx('lia-g-icon-btn lia-g-mr-10')}
            variant={ButtonVariant.NO_VARIANT}
            disabled={isSubmitting}
            onClick={() => {
              updateItems(
                items.filter((filterItem: ItemType) => {
                  if (isIdeaStatus(filterItem) && isIdeaStatus(item)) {
                    return filterItem.statusKey !== item.statusKey;
                  } else if (!isIdeaStatus(filterItem) && !isIdeaStatus(item)) {
                    return filterItem.id !== item.id;
                  }
                })
              );
            }}
            title={formatMessage('removeBtn')}
            aria-label={formatMessage('removeBtn')}
            data-testid="EditFeaturedList.RemoveBtn"
          >
            <Icon icon={Icons.CloseIcon} className={cx('lia-close-icon')} size={IconSize.PX_14} />
          </Button>
        </>
      );
    },
    [cx, formatMessage, isSubmitting, items, renderItemComponent, updateItems]
  );

  /**
   * Renders the draggable item
   *
   * @param item the item
   * @param idx the index of the item in the list
   */
  const renderDraggable = useCallback(
    (item: ItemType, idx: number): React.ReactElement => {
      const draggableId = isIdeaStatus(item) ? uidSeed(item.statusKey) : uidSeed(item.id);

      return (
        <Draggable
          key={draggableId}
          draggableId={draggableId}
          isDragDisabled={isSubmitting}
          index={idx}
        >
          {(
            { innerRef: draggableRef, draggableProps, dragHandleProps }: DraggableProvided,
            { isDragging }: DraggableStateSnapshot
          ): React.ReactElement => {
            const {
              'data-rbd-draggable-context-id': dataRbdDraggableContextId,
              'data-rbd-draggable-id': dataRbdDraggableId,
              onTransitionEnd,
              style
            } = draggableProps;
            const {
              'aria-describedby': ariaDescribedby,
              'data-rbd-drag-handle-context-id': dataRbdDragHandleContextId,
              'data-rbd-drag-handle-draggable-id': dataRbdDragHandleDraggableId,
              draggable,
              onDragStart,
              role,
              tabIndex
            } = dragHandleProps || {};
            return (
              <div
                ref={draggableRef}
                data-rbd-draggable-context-id={dataRbdDraggableContextId}
                data-rbd-draggable-id={dataRbdDraggableId}
                onTransitionEnd={onTransitionEnd}
                style={style}
                className={cx('lia-draggable', { 'lia-section-dragging': isDragging })}
                aria-describedby={ariaDescribedby}
              >
                {renderItem(
                  item,
                  dataRbdDragHandleContextId,
                  dataRbdDragHandleDraggableId,
                  role,
                  draggable,
                  onDragStart,
                  tabIndex
                )}
              </div>
            );
          }}
        </Draggable>
      );
    },
    [cx, isSubmitting, renderItem, uidSeed]
  );

  /**
   * Renders the droppable area for draggable items
   */
  const renderDroppable = useCallback((): React.ReactElement => {
    return (
      <Droppable droppableId="droppable">
        {(
          { innerRef: droppableRef, placeholder, droppableProps }: DroppableProvided,
          { isDraggingOver }: DroppableStateSnapshot
        ): React.ReactElement => {
          const {
            'data-rbd-droppable-id': dataRbdDroppableId,
            'data-rbd-droppable-context-id': dataRbdDroppableContextId
          } = droppableProps;
          return (
            <div
              className={cx(className)}
              ref={droppableRef}
              data-rbd-droppable-id={dataRbdDroppableId}
              data-rbd-droppable-context-id={dataRbdDroppableContextId}
              data-testid="EditFeaturedList"
            >
              {items.map((item: ItemType, idx: number) => renderDraggable(item, idx))}
              {placeholder}
              {isDraggingOver && (
                <div
                  className={cx('lia-placeholder')}
                  style={{ ...placeholderProps, ...placeholderStyle }}
                />
              )}
            </div>
          );
        }}
      </Droppable>
    );
  }, [cx, items, placeholderProps, renderDraggable, className]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      {renderDroppable()}
    </DragDropContext>
  );
};
export default EditFeaturedList;
