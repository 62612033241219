import type { QueryResult } from '@apollo/client/react';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import { FormFieldVariant } from '@aurora/shared-client/components/form/enums';
import InputEditForm from '@aurora/shared-client/components/form/InputEditForm/InputEditForm';
import type { NodePickerFieldSpec } from '@aurora/shared-client/components/form/NodePickerField/NodePickerField';
import type { FormSpec } from '@aurora/shared-client/components/form/types';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import NodeTitle from '@aurora/shared-client/components/nodes/NodeTitle/NodeTitle';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import FormBuilder from '@aurora/shared-client/helpers/form/FormBuilder/FormBuilder';
import type { StoreFeaturedPlacesError } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import type { GraphQLError } from 'graphql';
import React, { useContext, useState } from 'react';
import { Modal, useClassNameMapper } from 'react-bootstrap';
import type { ValidateResult } from 'react-hook-form';
import type {
  FeaturedPlacesWidgetQuery,
  FeaturedPlacesWidgetQueryVariables,
  NodeViewFragment,
  ProvisionedFeaturedPlacesQuery,
  StoreFeaturedPlacesMutation,
  StoreFeaturedPlacesMutationVariables
} from '../../../../types/graphql-types';
import EditContext from '../../../context/EditContext/EditContext';
import type { GenericNode } from '../../../managecontent/types';
import useTranslation from '../../../useTranslation';
import EditFeaturedList from '../../EditFeaturedList/EditFeaturedList';
import FeaturedWidgetLastModifiedInfo from '../../FeaturedWidgetLastModifiedInfo/FeaturedWidgetLastModifiedInfo';
import placesQuery from '../FeaturedPlacesWidget/FeaturedPlacesWidget.query.graphql';
import storePlacesMutation from '../FeaturedPlacesWidget/StoreFeaturedPlaces.mutation.graphql';
import useFeaturedPlacesWidget from '../FeaturedPlacesWidget/useFeaturedPlacesWidget';
import formSchema from './AddFeaturedPlacesModal.form.json';
import localStyles from './AddFeaturedPlacesModal.module.pcss';

interface PlaceSearchFormData {
  /**
   * The selected place
   */
  selectedPlace: NodeViewFragment;
}

interface Props {
  /**
   * Whether to show the modal
   */
  show: boolean;
  /**
   * Callback invoked to hide the modal
   */
  onHide: () => void;
  /**
   * Variables for the FeaturedPlacesWidget query
   */
  placesQueryVariables: FeaturedPlacesWidgetQueryVariables;
}

const log = getLog(module);

/**
 * Renders a modal with an input used to search for places to add to the FeaturedPlacesWidget
 *
 * @author Luisina Santos
 */
const AddFeaturedPlacesModal: React.FC<React.PropsWithChildren<Props>> = ({
  show,
  onHide,
  placesQueryVariables
}) => {
  const {
    publicConfig: { auroraFeaturedPlacesNodesLimit }
  } = useContext(TenantContext);
  const {
    contextNode: { nodeType, title: nodeTitle }
  } = useContext(AppContext);
  const { onChange, quilt } = useContext(EditContext);
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.ADD_FEATURED_PLACES_MODAL);
  const { formatMessage, loading: textLoading, FormattedMessage } = i18n;
  const { addToast } = useToasts();
  const [selectedPlaces, setSelectedPlaces] = useState<Array<NodeViewFragment>>([]);

  /**
   * Updates the selected places in component state with the original places stored on the widget
   *
   * @param data the data from the FeaturedPlacesWidgetQuery result
   */
  function initializeSelectedPlaces(
    data: FeaturedPlacesWidgetQuery | ProvisionedFeaturedPlacesQuery
  ): void {
    if ('featuredPlacesWidget' in data) {
      setSelectedPlaces(data.featuredPlacesWidget.coreNodes.edges.map(({ node }) => node));
    } else {
      setSelectedPlaces(data.coreNodes.edges.map(({ node }) => node));
    }
  }

  const {
    data: placesQueryData,
    loading: placesQueryLoading,
    error: placesQueryError
  }: QueryResult<
    FeaturedPlacesWidgetQuery,
    FeaturedPlacesWidgetQueryVariables
  > = useFeaturedPlacesWidget(
    {
      ...placesQueryVariables,
      first: auroraFeaturedPlacesNodesLimit
    },
    !show,
    'no-cache',
    (data: FeaturedPlacesWidgetQuery | ProvisionedFeaturedPlacesQuery) =>
      initializeSelectedPlaces(data)
  );

  const [updateFeaturedPlaces, { loading: updateFeaturedPlacesMutationLoading }] =
    useMutationWithTracing<StoreFeaturedPlacesMutation, StoreFeaturedPlacesMutationVariables>(
      module,
      storePlacesMutation
    );

  /**
   * Resets the selected places and closes the modal
   */
  function onModalClose(): void {
    initializeSelectedPlaces(placesQueryData);
    onHide();
  }

  /**
   * Updates the places used by the featured place component
   */
  async function onUpdateFeaturedPlaces(): Promise<void> {
    const placeIds: Array<string> = selectedPlaces.map((place: NodeViewFragment) => place.id);
    const { instanceId, quiltId, coreNodeId } = placesQueryVariables;
    // immediately persist messages if changes are submitted outside of page builder
    if (!quilt) {
      const { data, errors } = await updateFeaturedPlaces({
        variables: {
          instanceId,
          places: placeIds,
          quiltId,
          coreNodeId
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: placesQuery,
            variables: placesQueryVariables
          }
        ]
      });
      if (errors || data?.storeFeaturedPlaces?.errors) {
        errors?.map((error: GraphQLError) => log.error('error storing featured places', error));
        data?.storeFeaturedPlaces?.errors?.map((error: StoreFeaturedPlacesError) =>
          log.error('error storing featured places', error)
        );
        addToast({
          toastVariant: ToastVariant.BANNER,
          alertVariant: ToastAlertVariant.DANGER,
          title: formatMessage('failureTitle'),
          message: formatMessage('failureMessage'),
          autohide: true,
          delay: 4000,
          id: `AddFeaturedPlacesModal-StoreFeaturedPlaces-${instanceId}-Error`
        });
        return;
      } else {
        addToast({
          toastVariant: ToastVariant.FLYOUT,
          alertVariant: ToastAlertVariant.SUCCESS,
          title: formatMessage('successTitle'),
          message: formatMessage('successMessage'),
          autohide: true,
          delay: 4000,
          id: `AddFeaturedPlacesModal-StoreFeaturedPlaces-${instanceId}-Success`
        });
        onHide();
      }
    } else {
      // store the changes in the page editor session and invoke the mutation during publish
      const sessionInstance: FeaturedWidgetInstance = {
        componentId: EndUserComponent.FEATURED_PLACES_WIDGET,
        instanceId: placesQueryVariables.instanceId,
        featuredItemIds: placeIds,
        coreNodeId: placesQueryVariables.coreNodeId
      };

      onChange(quilt, null, sessionInstance);
      onHide();
    }
  }

  if (placesQueryError) {
    log.error(
      `error retrieving places for FeaturedPlacesWidget with instance id ${placesQueryVariables.instanceId}`,
      placesQueryError.message
    );
  }

  if (textLoading || placesQueryLoading || placesQueryError) {
    return null;
  }

  const placesLimitReachedFeedback: string = formatMessage(
    'AddFeaturedPlacesModal.selectedPlace.validate.placeLimitCheck.error',
    { limit: auroraFeaturedPlacesNodesLimit }
  );

  const isPlacesLimitReached: boolean = selectedPlaces.length >= auroraFeaturedPlacesNodesLimit;
  const placeSearchField: NodePickerFieldSpec<'selectedPlace', PlaceSearchFormData> = {
    fieldVariant: FormFieldVariant.NODE_PICKER,
    name: 'selectedPlace',
    defaultValue: null,
    disabled: isPlacesLimitReached || updateFeaturedPlacesMutationLoading,
    disabledProps: {
      useDisabledOpacity: true,
      isDisabledFn(node: GenericNode) {
        if (node.nodeType === NodeType.COMMUNITY) {
          return true;
        }
        return selectedPlaces.some(place => place.id === node.id);
      },
      disabledReasonText: () => formatMessage('disabledReasonText')
    },
    validations: {
      validate: {
        placeLimitCheck: (): ValidateResult => {
          if (isPlacesLimitReached) {
            return placesLimitReachedFeedback;
          }
        }
      }
    },
    focus: true,
    clearInputOnChange: true,
    excludeNodeIds: selectedPlaces.map((place: NodeViewFragment) => place.id)
  };

  const placeSearchFormSpec: FormSpec<PlaceSearchFormData> = new FormBuilder<PlaceSearchFormData>(
    'AddFeaturedPlacesModal',
    i18n,
    {
      cx,
      schema: formSchema
    },
    {
      formClassName: cx('lia-g-mb-20')
    }
  )
    .addField(placeSearchField)
    .build();

  /**
   * Renders the timestamp and user login associated with the last modification to the widget contents
   */
  function renderLastModifiedHistory(): React.ReactElement {
    const { lastModified, lastModifiedUser } = placesQueryData.featuredPlacesWidget;
    return (
      <FeaturedWidgetLastModifiedInfo
        lastModified={lastModified}
        lastModifiedUser={lastModifiedUser.login}
      />
    );
  }

  /**
   * Renders the node contents for use in the drag and drop list
   *
   * @param place the node
   */
  function renderItem(place: NodeViewFragment): React.ReactElement {
    return (
      <>
        <NodeIcon size={IconSize.PX_16} node={place} className={cx('flex-shrink-0')} />
        <NodeTitle node={place} as="span" className={cx('lia-g-clamp m-0')} />
      </>
    );
  }

  /**
   * Renders the description that appears the modal header
   */
  function renderDescription() {
    return (
      <span className={cx('lia-description')}>
        <FormattedMessage
          // use a different description text if the modal is rendered inside of page builder
          id={quilt ? 'PageEditor.header.description' : 'header.description'}
          values={{ bold: (chunks: string) => <strong>{chunks}</strong>, nodeType, nodeTitle }}
        />
      </span>
    );
  }

  return (
    <Modal show={show} onHide={() => onModalClose()} size="sm" data-testid="AddFeaturedPlacesModal">
      <Modal.Header closeButton>
        {/*use a different header text if the modal is rendered inside of page builder*/}
        <Modal.Title>{formatMessage(quilt ? 'PageEditor.header' : 'header')}</Modal.Title>
      </Modal.Header>
      {renderDescription()}
      <Modal.Body>
        <InputEditForm<PlaceSearchFormData>
          formSpec={placeSearchFormSpec}
          onSubmit={(formData: PlaceSearchFormData, actionId) => {
            if (actionId === 'submit' && formData.selectedPlace) {
              setSelectedPlaces(previousPlaces => [formData.selectedPlace, ...previousPlaces]);
            }
          }}
          submitOnChange={{ watchFields: ['selectedPlace'], waitTime: 0 }}
        />
        {isPlacesLimitReached && (
          <span
            className={cx('lia-g-validation-error lia-feedback')}
            data-testid="AddFeaturedPlacesModal.Feedback"
          >
            {placesLimitReachedFeedback}
          </span>
        )}
        {selectedPlaces.length > 0 && (
          <EditFeaturedList
            items={selectedPlaces}
            updateItems={places => setSelectedPlaces(places)}
            isSubmitting={updateFeaturedPlacesMutationLoading}
            renderItemComponent={renderItem}
            className={cx('lia-g-mb-20')}
          />
        )}
        {!!placesQueryData?.featuredPlacesWidget?.lastModifiedUser && renderLastModifiedHistory()}
        <div className={cx('lia-g-modal-buttons-wrap')}>
          <Button
            size="lg"
            loading={updateFeaturedPlacesMutationLoading}
            onClick={async () => await onUpdateFeaturedPlaces()}
            data-testid="AddFeaturedPlacesModal.Submit.Btn"
          >
            {/*use preview text as submit button text if the modal is rendered inside of page builder*/}
            {formatMessage(quilt ? 'previewBtn' : 'submitBtn')}
          </Button>
          <Button
            variant={ButtonVariant.LIGHT}
            size="lg"
            onClick={() => onModalClose()}
            disabled={updateFeaturedPlacesMutationLoading}
            data-testid="AddFeaturedPlacesModal.Cancel.Btn"
          >
            {formatMessage('cancelBtn')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddFeaturedPlacesModal;
