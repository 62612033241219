import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';
import useTranslation from '../../useTranslation';
import localStyles from './FeaturedWidgetLastModifiedInfo.module.pcss';

interface Props {
  /**
   * Last modified time for the featured widget
   */
  lastModified: number;
  /**
   * Login for the User who modified the featured widget most recently
   */
  lastModifiedUser: string;
}

/**
 * Renders a last modified info for a given Featured Widget
 *
 * @author Luisina Santos
 */
const FeaturedWidgetLastModifiedInfo: React.FC<React.PropsWithChildren<Props>> = ({
  lastModified,
  lastModifiedUser
}) => {
  const cx = useClassNameMapper(localStyles);
  const { FormattedMessage } = useTranslation(EndUserComponent.FEATURED_WIDGET_LAST_MODIFIED);

  return (
    <div className={cx('lia-last-modified')}>
      <FormattedMessage
        id="lastModified"
        values={{
          lastModifiedUser: <strong className={cx('lia-user')}>{lastModifiedUser}</strong>,
          lastModified: (
            <FormattedDate value={lastModified} year="numeric" month="long" day="2-digit" />
          )
        }}
      />
    </div>
  );
};

export default FeaturedWidgetLastModifiedInfo;
