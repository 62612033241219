import type { DraggableId, DraggableLocation } from 'react-beautiful-dnd';

export interface PlaceholderProps {
  /**
   * The top distance from the top edge where the placeholder should be on.
   */
  top: number;
  /**
   * The left distance from the left edge where the placeholder should be on.
   */
  left: number;
  /**
   * The width of the placeholder.
   */
  width: number;
  /**
   * The height of the placeholder.
   */
  height: number;
}

export const placeholderStyle: React.CSSProperties = {
  position: 'absolute',
  backgroundColor:
    'hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.05)',
  border:
    '1px dashed hsla(var(--lia-bs-primary-h), var(--lia-bs-primary-s), var(--lia-bs-primary-l), 0.5)',
  borderRadius: 'var(--lia-bs-border-radius-sm)'
};

export const placeholderInitialState = {
  top: 0,
  left: 0,
  width: 0,
  height: 0
};

/**
 * Returns the element currently being dragged.
 */
function getDraggedElement(queryAttribute: string, draggableId: string): Element {
  // Sometimes the draggableId may contain apostrophes so we must escape them to make the query valid.
  // This will become irrelevant after LIA-83684, when items are assigned stable IDs instead of using the label as an ID, TODO
  const id = draggableId.replaceAll("'", "\\'");

  const domQuery = `[${queryAttribute}='${id}']`;
  return document.querySelector(domQuery);
}

export interface CalculatePlaceholderPropsInput {
  /** The destination according to react-beautiful-dnd */
  destination: DraggableLocation;

  /** The source according to react-beautiful-dnd */
  source: DraggableLocation;

  /** The id of the item that is being dragged according to react-beautiful-dnd */
  draggableId: DraggableId;

  /** The attribute that is applied to the drag items used for a querySelector (different depending on which version of react-beautiful-dnd) */
  queryAttribute: string;

  /** A function that implements how to find the container element for any given draggable element */
  getRootElement: (draggedElement: Element) => Element;

  /** How many pixels are applied as padding per nest level */
  paddingPerLevel?: number;

  /** How many nest levels have been detected by react-beautiful-dnd */
  horizontalLevel?: number;
}

/**
 * @param input the CalculatePlaceholderPropsInput value
 * @returns PlaceholderProps for the new location
 */
export function calculatePlaceholderProps(input: CalculatePlaceholderPropsInput): PlaceholderProps {
  const {
    destination,
    source,
    draggableId,
    queryAttribute,
    getRootElement,
    paddingPerLevel,
    horizontalLevel
  } = input;

  if (!destination) {
    return;
  }

  const draggedDOM = getDraggedElement(queryAttribute, draggableId);
  const rootElement = getRootElement(draggedDOM);

  if (!rootElement) {
    return;
  }

  const horizontalAdjustment =
    paddingPerLevel && horizontalLevel ? paddingPerLevel * (horizontalLevel - 1) : 0;

  const { style, clientLeft, parentElement } = rootElement as HTMLElement;

  const width = Number.parseFloat(style.width) - horizontalAdjustment;
  const height = Number.parseFloat(style.height);
  const destinationIndex = destination.index;
  const sourceIndex = source.index;

  const childrenArray = [...parentElement.children];

  const movedSection = childrenArray[sourceIndex];
  childrenArray.splice(sourceIndex, 1);

  const updatedSections = [
    ...childrenArray.slice(0, destinationIndex),
    movedSection,
    ...childrenArray.slice(destinationIndex + 1)
  ];

  /**
   * Calculates the top distance value where the placeholder should be on the y axis
   based on the reordered section array, slicing this from the start to the where
   we are dropping the section item (destinationIndex), reducing and fetching margins
   and heights styles of each item, accumulating them and assigning that to top.
   Ref: https://medium.com/@larryliu_50901/how-to-finally-add-a-custom-placeholder-react-beautiful-dnd-1b1359a94e30
   */
  const top =
    parentElement.offsetTop +
    // eslint-disable-next-line unicorn/no-array-reduce
    updatedSections.slice(0, destinationIndex).reduce((total, current) => {
      const currentStyle = window.getComputedStyle(current);
      const marginBottom = Number.parseFloat(currentStyle.marginBottom);
      return total + current.clientHeight + marginBottom;
    }, 0);

  /**
   * Calculates the left distance value where the placeholder should be on the x axis
   based on the parent left offset and left section item value.
   */
  const left = parentElement.offsetLeft + horizontalAdjustment + clientLeft;

  return {
    top,
    left,
    width,
    height
  };
}
